// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import H2 from "~components/typography/H2";
import Card from "~components/cards/Card";
import Grid from "~components/common/Grid";
import Container from "~components/common/Container";
import PageHeader from "~components/sections/PageHeader";
import Layout from "~components/layouts/Layout";
import SubTitle from "~components/typography/SubTitle";
import P from "~components/typography/P";
import Ul from "~components/typography/Ul";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	products: {
		nodes: Product[];
		distinct: string[];
	};
	imageWorkshopSpeedReading: IGatsbyImageData;
	imageWorkshopYourOwnMoneyStrategy: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Workshops"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Workshops"
				title="Unsere Kurse"
				description="Mit unseren Workshops kannst Du gezielt neue Techniken lernen und Deine Talente stärken."
			/>
			<Container className="space-y-8">
				<Grid cols={2}>
					<Card
						title="Firmen Speed Reading Workshops"
						to="/workshops/firmen-speed-reading/"
						imageData={data.imageWorkshopYourOwnMoneyStrategy}
						description="Leaders are Readers: Maximale Effizienz durch verbesserte Lesegeschwindigkeit und Informationsverarbeitung"
						buttonText="Zum Firmen Speed Reading Workshop"
						alt="Firmen Speed Reading Workshops"
					/>
					<Card
						title="Juristen Speed Reading Workshops"
						to="/workshops/juristen-speed-reading/"
						imageData={data.imageWorkshopSpeedReading}
						description="Leaders are Readers: Maximiere die Effizienz deiner juristischen Praxis"
						buttonText="Zum Juristen Speed Reading Workshop"
						alt="Juristen Speed Reading Workshops"
					/>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageWorkshopSpeedReading: file(relativePath: { eq: "pages/workshops/workshops_speed-reading.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageWorkshopYourOwnMoneyStrategy: file(
			relativePath: { eq: "pages/workshops/workshops_your-own-money-strategy.jpg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
